/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    array, bool, object, shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import MenuBuilder from '../../../Header/components/LeftMenu/MenuBuilder/MenuBuilder';
import extractMenuAttributes from '../../../../helpers/contentstack/extractMenuAttributes';
import customBreakpoints from '../../../../helpers/customBreakpoints';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    topMenuBar: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        width: '100%',
        height: theme.palette.navHeight || '52px',
        whiteSpace: 'nowrap',
        borderBottom: theme.palette.desktopHeaderMenu.border,
    },
    topMenuBarSecondary: {
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            height: theme.palette.navSecondaryHeight || '52px',
        },
    },
    isCategoryOffStyle: {
        marginTop: 16,
    },
}));

//
// The horizontal menu that appears at top of home and possibly other pages
// It takes input from graphql/CMS (PWA system)
//
// Props:
//    menu               Hierarchical array of menu items (hdr.data.findHeader.content.entries[0].header_blocks[0].menu.menu_ref[0].menu_blocks)
//    brand              brand object (must contain brand.code)
//
const  TopMenu = ({
    menu, brand, attributes, ariaLabel, keeperData,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (ariaLabel === 'Main Navigation') {
            dispatch(trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Navigation Menu v1',
                eventLabel: '<<pageType>>', // not required for impression tag so we used page type
            }));
        }
    }, []);

    const isSecondary = ariaLabel === 'Secondary Navigation';
    const attributesClone = JSON.parse(JSON.stringify(attributes));
    const isCategoryOff = menu?.some((item) => item?.menu_groups?.category_offers);
    return (
        <nav className={`${classes.topMenuBar} ${isCategoryOff && classes.isCategoryOffStyle} ${isSecondary && classes.topMenuBarSecondary}`} style={extractMenuAttributes(attributesClone)} aria-label={ariaLabel}>
            <MenuBuilder keeperData={keeperData} menu={menu} brand={brand} attributes={attributesClone} target="Desktop" type={isSecondary && 'secondary'} />
        </nav>
    );
};

TopMenu.propTypes = {
    menu: array.isRequired,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    attributes: shape({
        dropdown_menu_item_no_wrap: bool,
        dropdown_menu_item_padding: string,
    }),
    ariaLabel: string.isRequired,
    keeperData: object,
};
TopMenu.defaultProps = {
    attributes: {},
    keeperData: {},
};

export default TopMenu;
